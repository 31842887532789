@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'SFMonoMedium';
    src: url('../public/fonts/SF-Mono-Regular.ttf') format('truetype');
    /* Otras propiedades como font-weight y font-style si es necesario */
  }
  
  body {
    font-family: 'SFMonoMedium', sans-serif;
    font-size: small;
    overflow-x: hidden;
  }
